import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "./AuthContext";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "./app.css";

function App() {
  const { isAuthenticated, login, updateUserData, userData, logout } =
    useAuth();
  const [loading, setLoading] = useState(false);
  //edit
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [counts, setCounts] = useState({});

  const customers = [
    "zuidzorg",
    "buurtzorgnederland",
    "buurtzorgt",
    "accoladezorg",
    "buurtdiensten",
    "elver",
    "plu",
  ];
  const licenseTypes = [
    "Enterprise Standard",
    "Frontline Starter",
    "Enterprise Plus",
    "Frontline Standard",
    "Business Plus",
  ];

  // Fetch license count
  const fetchLicenseCount = async (customer, licenseType) => {
    try {
      const response = await axios.get(
        `https://us-central1-klantportaal-b94af.cloudfunctions.net/app/api/license/${customer}/${licenseType}`
      );
      console.log("Get error =  ", response);

      console.log(`Customer: ${customer}, LicenseType: ${licenseType}`);
      console.log("Response data:", response.data);

      // Set the count in state
      setCounts((counts) => ({
        ...counts,
        [`${customer}_${licenseType}_name`]: licenseType,
        [`${customer}_${licenseType}_stock`]: response.data.stockCount,
        [`${customer}_${licenseType}_inuse`]: response.data.licenseCount,
        [`${customer}_${licenseType}_free`]:
          response.data.stockCount - response.data.licenseCount,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch count on ComponentDidMount
    customers.forEach((customer) =>
      licenseTypes.forEach((licenseType) =>
        fetchLicenseCount(customer, licenseType)
      )
    );

    // Fetch count every 30 minutes
    const intervalId = setInterval(() => {
      customers.forEach((customer) =>
        licenseTypes.forEach((licenseType) =>
          fetchLicenseCount(customer, licenseType)
        )
      );
    }, 1800000);

    // Clear interval on ComponentWillUnmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("isAuthenticated");
    const storedUserDetails = localStorage.getItem("userdetails");

    if (storedUser && !isAuthenticated) {
      const user = JSON.parse(storedUser);
      login(user);

      // Update user details using 'storedUserDetails' if available
      const userDetails = storedUserDetails
        ? JSON.parse(storedUserDetails)
        : null;
      updateUserData(userDetails || user);
    }
  }, [login, updateUserData, isAuthenticated, userData]);

  const handleLogout = () => {
    logout();
  };

  const handleLoginSuccess = async (credentialResponse) => {
    setLoading(true);

    try {
      const credentialResponseDecoded = jwtDecode(
        credentialResponse.credential
      );
      const userEmail = credentialResponseDecoded.email;
      const updatedUserData = {
        ...credentialResponseDecoded,
      };

      login(updatedUserData);

      localStorage.setItem("isAuthenticated", JSON.stringify(updatedUserData));
    } catch (error) {
      console.error("Error fetching additional user data:", error);
    }

    setLoading(false);

    navigate("/");
  };

  const keyMapping = {
    _name: "",
    _inuse: "Toegewezen",
    _stock: "Voorraad",
    _free: "Vrij",
  };

  const saveToDatabase = async (newCount) => {
    const response = await fetch(
      "https://us-central1-klantportaal-b94af.cloudfunctions.net/app/api/license/zz/entstd/stock",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ licenseCount: newCount }),
      }
    );
    const data = await response.json();
    console.log(data.message);
  };

  return (
    <GoogleOAuthProvider clientId="840966931015-u3u448toblqaevesvbvt6o4jm7al2ots.apps.googleusercontent.com">
      <div className="app">
        {isAuthenticated ? (
          <Link className="logoutbutton" onClick={handleLogout}>
            Uitloggen
          </Link>
        ) : (
          <Link className="logoutbutton" to="/">
            Inloggen
          </Link>
        )}

        <div className="pagename">Google Workspace licentie overzicht.</div>
        {isAuthenticated ? (
          <table>
            <tbody>
              <tr>
                {customers.map((customer) => (
                  <td>
                    <div className={`client_${customer}`}>
                      {customer.charAt(0).toUpperCase() + customer.slice(1)}
                    </div>
                    {Object.entries(counts)
                      .filter(([key]) => key.startsWith(`${customer}`))
                      .map(([key, count], index, array) => {
                        const parts = key.split("_");
                        const licenseType = parts[1];
                        const newName = keyMapping[`_${parts[2]}`];

                        // Check if stock count is 0, hide all counts if it is
                        if (parts[2] === "stock" && count === 0) return null;

                        // Hide individual counts if stock count is 0
                        if (
                          parts[2] !== "stock" &&
                          parts[2] !== "name" &&
                          parts[2] !== "client" &&
                          counts[`${parts[0]}_${licenseType}_stock`] === 0
                        )
                          return null;

                        // Hide license name if stock count is 0
                        if (
                          parts[2] === "name" &&
                          counts[`${parts[0]}_${licenseType}_stock`] === 0
                        )
                          return null;

                        const isLastRow = index === array.length - 1;
                        const isNextLicenseType =
                          !isLastRow &&
                          array[index + 1][0].split("_")[1] !== licenseType;

                        return (
                          <>
                            <div className="count" key={key}>
                              {newName} {count}
                            </div>
                            {isLastRow || isNextLicenseType ? <br /> : null}{" "}
                          </>
                        );
                      })}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="loginbutton">
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              cookiePolicy={"single_host_origin"}
              type="standard"
              size="medium"
              shape="circle"
              width="200px"
              ux_mode="popup"
              theme="filled_blue"
              text="continue_with"
            />
          </div>
        )}
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
